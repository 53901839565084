import React, { useEffect } from 'react';
import CarouselJsonld from 'components/CarouselJsonld';
import IfrmaeViewBlocker from 'components/IfrmaeViewBlocker';
import LayoutPage from 'components/layoutPage/LayoutPage';
import config from 'config';
import getSystemUserAccessToken from 'helpers/getSystemUserAccessToken';
import HomepageCompoundContext from 'modules/context/CompoundContexts/HomepageContext';
import fetchProducts from 'modules/product/helpers/fetchProducts';
import { ProductStatusEnum } from 'modules/product/types';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import fetchSiteConfig from 'modules/siteConfig/query/fetchSiteConfig';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { Service } from 'types/interfaces/SiteConfig';

import { Box, Container } from '@lyearn/ui';

export default function Home({
  host,
  products,
  productUrls,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter();
  const { site, pages } = useInstanceConfig();
  const { siteName, homepage } = site.seo;
  const image = site.homepageData?.header.logoUrl;
  const url = `https://${host}`;

  useEffect(() => {
    if (typeof site.homepageRedirection === 'string') {
      router.replace(site.homepageRedirection);
    }
  }, [router, site.homepageRedirection]);

  if (site.homepageRedirection) {
    return null;
  }

  return (
    <>
      <IfrmaeViewBlocker>
        <HomepageCompoundContext products={products}>
          <Box>
            <Container className="" component="main">
              <LayoutPage layoutConfig={pages.HOME_LAYOUT} />
            </Container>
          </Box>
          <NextSeo
            canonical={url}
            description={homepage.description}
            openGraph={{
              title: homepage.title,
              description: homepage.description,
              site_name: siteName,
              type: 'website',
              images: [{ url: image }],
            }}
            title={homepage.homepageTitle ?? homepage.title}
            titleTemplate={homepage.homepageTitle ?? homepage.title}
            twitter={{
              site: site.seo.twitterHandle,
              cardType: 'summary_large_image',
            }}
          />
          <CarouselJsonld urls={productUrls} />
        </HomepageCompoundContext>
      </IfrmaeViewBlocker>
    </>
  );
}

export async function getStaticPaths() {
  // build paths for each of the sites in the previous two lists
  const paths = [
    ...config.default_build_hosts.map((host) => {
      return { params: { site: host } };
    }),
  ];
  return {
    paths: paths,
    fallback: 'blocking', // fallback blocking allows sites to be generated using ISR
  };
}

export const getStaticProps: GetStaticProps = async (context) => {
  const token = getSystemUserAccessToken(context.params?.site);
  const siteConfig = await fetchSiteConfig({ token, host: context.params?.site as string });

  const products = await fetchProducts(
    {
      filters: {
        status: [ProductStatusEnum.Listed],
      },
    },
    { token },
  );

  /**
   * productUrls for JSON LD - SEO
   */
  const productUrls = products?.map(
    (product) => `https://${siteConfig.site.host}/${product.SEOSettings?.slug}`,
  );

  /* Antarakt services */
  const servicesUrls = (siteConfig.site.services || []).map(
    (service: Service) => `https://${siteConfig.site.host}/service/${service.page}`,
  );

  return {
    props: {
      host: siteConfig.site.host,
      productUrls: [...servicesUrls, ...productUrls],
      products: products,
      siteConfig,
    },
    revalidate: 180,
  };
};
