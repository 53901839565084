import React from 'react';

import { Typography } from '@lyearn/ui';

const IfrmaeViewBlocker: React.FC<React.PropsWithChildren> = ({ children }) => {
  const goToHome = () => {
    if (process.browser && window && window.top) {
      window.top.location.href = '/';
    }
  };
  if (typeof window !== 'undefined' && window.top !== window) {
    return (
      <Typography className="w-full py-30 text-center" variant="body-short-01">
        Something went wrong, please click{' '}
        <a className="textlink-external underline" href="/#" onClick={goToHome}>
          Here
        </a>
        .
      </Typography>
    );
  }

  return <>{children}</>;
};

export default IfrmaeViewBlocker;
