import React from 'react';
import { UserProduct } from 'modules/product/types';

import { ProductsContextProvider } from '../Contexts/Products';

type HomepageCompoundContextProps = {
  products: UserProduct[];
};

const HomepageCompoundContext: React.FC<React.PropsWithChildren<HomepageCompoundContextProps>> = (
  props,
) => {
  return <ProductsContextProvider {...props} />;
};

export default HomepageCompoundContext;
