import React from 'react';
import Head from 'next/head';

const CarouselJsonld = ({ urls }: { urls: string[] }) => {
  const itemListElement = urls.map((url, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    url,
  }));
  const jsonld = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement,
  });

  return (
    <Head>
      <script
        key="jsonld-course"
        dangerouslySetInnerHTML={{ __html: jsonld }}
        type="application/ld+json"
      />
    </Head>
  );
};

export default CarouselJsonld;
